import styled from 'styled-components';

export const Container = styled.div`

    background: #FFF;
    height: auto;
    margin: 40px 40px;
    font-family: Montserrat;
    font-style: normal;
  
`;